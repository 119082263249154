export const checkedDeepFormat = (base = []) => {
  let flat = []
  let flat_parent_obj = {}
  let ids = {}
  let parentIds = {}
  let id_name = {}
  const flatarr = (array, deep) => {
    array.forEach(item => {
      ids[item.id] = item.id
      id_name[item.id] = item.name
      flat.push(item)
      if (!deep) {
        flat_parent_obj[item.id] = item
      }
      if (item.parentId || item.fatherId) {
        parentIds[item.id] = item.parentId || item.fatherId
      }
      if (item?.childrenList?.length) {
        flatarr(item.childrenList, true)
      }
    })
  }
  const findCheckedNameById = id => {
    if (!id || !ids[id] || !id_name[id]) return ''
    let checkedName = id_name[id]
    if (parentIds[id] && id_name[parentIds[id]]) {
      checkedName = findCheckedNameById(parentIds[id]) + '/' + checkedName
    }
    // log.info(checkedName, 'checkedName')
    return checkedName
  }

  const findActiveParent = id => {
    if (!id) return
    if (flat_parent_obj[id]) {
      return flat_parent_obj[id]
    }
    // if (parentIds[id] && flat_parent_obj[parentIds[id]]) {
    //   return flat_parent_obj[parentIds[id]]
    // }
    return findActiveParent(parentIds[id])
  }
  const findOwnerNameById = id => {
    if (!id) return
    return id_name[id] || ''
  }

  flatarr(base)
  // log.info(flat_parent_obj, 'flat_parent_obj')
  // log.info(flat, 'flat')
  // log.info(ids, 'ids')
  // log.info(parentIds, 'parentIds')
  // log.info(id_name, 'id_name')

  return {
    flat,
    base,
    findActiveParent,
    findOwnerNameById,
    findCheckedNameById
  }
}
// const formatres = checkedDeepFormat(area_json)

// formatres = {
//   flat,
//   base,
//   findCheckedNameById
// }

// formatres.findCheckedNameById(129)

import jsonObj from './index.json'

export const aa = () => {
  const formatres1 = checkedDeepFormat(jsonObj.serviceList)
  formatres1.findCheckedNameById(338)
  // log.info(formatres1.findActiveParent(338), 'checked.......................')
}
