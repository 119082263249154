import request from '@/utils/request'
/**
 *  产品模块 api
 */
// 所在地区列表
export const regionListApi = params =>
  request({
    url: '/area/list',
    method: 'get',
    params
  })

// 产品列表
export const productListApi = params =>
  request({
    url: '/product/list',
    method: 'get',
    params
  })

// 热门产品列表
export const hotProductListApi = params =>
  request({
    url: '/business/hotList',
    method: 'get',
    params
  })

// 收藏
export const collectApi = params =>
  request({
    url: '/collection/add',
    method: 'post',
    data: params
  })

// 取消收藏
export const cancelCollectApi = params =>
  request({
    url: '/collection/remove',
    method: 'post',
    data: params
  })

// 产品详情
export const getProductDetailApi = params =>
  request({
    url: `/product/${params.id}/detail`,
    method: 'get'
  })

// 保存产品信息
export const saveProductApi = params =>
  request({
    url: '/product/save',
    method: 'post',
    data: params
  })

//根据当前登录用户(公司管理员)获取公司信息
export const getCompanyInfoByAdminApi = () =>
  request({
    url: '/company/getCompanyInfoByAdmin',
    method: 'get'
  })
// 热门产品列表
export const getCompanyProductList = params =>
  request({
    url: `/product/${params.companyId}/list`,
    method: 'get',
    params
  })
export const getCompanyServeList = params =>
  request({
    url: `/service/${params.companyId}/list`,
    method: 'get',
    params
  })

export const checkVip = () =>
  request({
    url: `/member/describe`,
    method: 'get'
  })

export const delProduct = id =>
  request({
    url: `/product/remove/${id}`,
    method: 'DELETE'
  })
export const delService = id =>
  request({
    url: `/service/remove/${id}`,
    method: 'DELETE'
  })

// 判断是否是运营
export const checkAuth = params =>
  request({
    url: '/user/checkAuth',
    method: 'POST',
    data: params
  })

// 判断是否是运营
export const refundApplication = params =>
  request({
    url: `/order/${params.orderCode}/refund/application`,
    method: 'POST'
  })
