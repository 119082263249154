import request from '@/utils/request'

/**
 *   登录相关的接口api
 */

// 获取验证码
export const getVerifyCodeApi = params =>
  request({
    url: `/verifyCode/${params.phone}/${params.biz}`,
    method: 'get'
  })
// 注册
export const registerApi = params =>
  request({
    url: `/user/register`,
    method: 'post',
    data: JSON.stringify(params)
  })
// 密码登录
export const loginByPwdApi = params =>
  request({
    url: `/user/loginByPwd`,
    method: 'post',
    data: JSON.stringify(params)
  })
// 验证码登录
export const loginByCodeApi = params =>
  request({
    url: `/user/loginByCode`,
    method: 'post',
    data: JSON.stringify(params)
  })
// 找回密码
export const findPwdApi = params =>
  request({
    url: `/user/findPwd`,
    method: 'put',
    data: JSON.stringify(params)
  })
// 获取用户信息
export const getUserInfoApi = params =>
  request({
    url: `/user/getUserInfo/${params.phone}`,
    method: 'get'
  })
// 获取验证图片链接(方块版本)
export const slipPicBigApi = params =>
  request({
    url: `/slipPic/v1/${params.biz}/${params.phone}`,
    method: 'get'
  })
// 获取验证图片链接(锯齿版本)
export const slipPicSmallApi = params =>
  request({
    url: `/slipPic/v2/${params.biz}/${params.phone}`,
    method: 'get'
  })
// 验证滑块滑动距离  /slipPic/{biz}/{phone}/{xWidth}
export const checkSlipPicSmallApi = params =>
  request({
    url: `/slipPic/${params.biz}/${params.phone}/${params.xwidth}`,
    method: 'get'
  })

/**
 * 其他前置模块  公用模块
 */
// 知名互联网公司接口
export const getfamousInternetCompanyApi = () =>
  request({
    url: `/famousInternetCompany/list`,
    method: 'get'
  })
// 业务类型的接口 api   网站 app 小程序 类型枚举
export const getBusinessListApi = params =>
  request({
    url: `/business/list`,
    method: 'get',
    params
  })
// 地区信息接口 api
export const getAreaListApi = params =>
  request({
    url: `/area/list`,
    method: 'get'
  })
// 职位通道接口 api
export const getEmployeeChannelListApi = params =>
  request({
    url: `/employeeChannel/list`,
    method: 'get'
  })
// 文件上传接口 api
export const FileUpload = params =>
  request({
    url: `/file/upload`,
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
//  岗位接口 api   详情页个人职位枚举
export const getEmployeeStationListApi = params =>
  request({
    url: `/employeeStation/list`,
    method: 'get'
  })
//  学历接口 api   详情页个人
export const getEducationListApi = params =>
  request({
    url: `/education/list`,
    method: 'get'
  })
// 前置模块-职业
export const getEmployeeOccupationListApi = () =>
  request({
    url: '/employeeOccupation/list',
    method: 'get'
  })
// 前置模块-公司知名互联网公司
export const getFamousInternetCompanyListApi = () =>
  request({
    url: '/famousInternetCompany/list',
    method: 'get'
  })

export const getFamousInternetCompanyShortNameListApi = () =>
  request({
    url: '/famousInternetCompany/shortNameList',
    method: 'get'
  })
// 前置模块-职业-擅长领域关系
export const getEmployeeOccupationDomainClassRelationListApi = () =>
  request({
    url: '/employeeOccupationDomainClassRelation/list',
    method: 'get'
  })
// 前置模块-擅长领域
export const getEmployeeDomainListApi = () =>
  request({
    url: '/employeeDomain/list',
    method: 'get'
  })
// 前置模块-擅长领域分类
export const getEmployeeDomainClassListApi = () =>
  request({
    url: '/employeeDomainClass/list',
    method: 'get'
  })

export const employeeEnterCheckApi = () =>
  request({
    url: '/employee/enterCheck',
    method: 'get'
  })
export const teamEnterCheckApi = () =>
  request({
    url: '/team/enterCheck',
    method: 'get'
  })
export const companyEnterCheckApi = () =>
  request({
    url: '/company/enterCheck',
    method: 'get'
  })
