<template>
  <div class="check-box">
    <p>{{ title }}</p>
    <ul class="item-box" :class="isShowMore ? 'isShowMore' : ''">
      <li @click="selectAll" class="hoverstyle" style="margin-right:30px" v-if="!hideAll">
        <span :class="activeIndex == '' ? 'active box' : 'box'">全部</span>
      </li>
      <li
        class="hoverstyle"
        v-for="(item, i) in infoList"
        :key="`hoverstyle${i}`"
        style="margin-bottom: 15px;"
        v-if="
          activeIndex == 0 ||
            activeIndex == item[emitKeyName || 'id'] ||
            (item.idList && item.idList.includes(activeIndex))
        "
      >
        <span
          @click="updateActiveIndex(item)"
          v-if="
            (!hoverList || !hoverList.length) &&
              (!areaLevel || item.level == '2') &&
              (typeof item.childrenList !== 'object' || item.childrenList.length === 0)
          "
          :class="activeIndex == item[emitKeyName || 'id'] ? 'active box' : 'box'"
          @mouseover="mouseover(item)"
        >
          {{ item[keyname] }}
        </span>
        <el-popover
          popper-class="popover box"
          v-model="item.showPopover"
          placement="bottom"
          width="840"
          trigger="hover"
          v-if="
            (!hoverList || !hoverList.length) &&
              (!areaLevel || item.level == '2') &&
              Array.isArray(item.childrenList) &&
              item.childrenList.length !== 0
          "
        >
          <div class="pleasehloder"></div>
          <div class="children-content">
            <div class="children-option" v-for="itm in item.childrenList" :key="itm.id">
              <div class="sub-title" @click="updateActiveIndex(item, itm, '', '', i)">
                <p class="point"></p>
                <span>{{ itm.name }}</span>
                <p class="hot" v-if="itm.hot" style="margin-top: 3.5px;">热</p>
              </div>
              <div
                class="children-name"
                v-for="child in itm.childrenList"
                :key="child.id"
                @click="updateActiveIndex(item, itm, child, 'child')"
                :class="activeIndex == item[emitKeyName || 'id'] ? 'active' : ''"
              >
                <p class="option-name">{{ child.name }}</p>
                <p class="hot" v-if="child.hot">热</p>
              </div>
            </div>
          </div>
          <span
            slot="reference"
            v-if="activeIndex !== item[emitKeyName || 'id'] && item.idList && !item.idList.includes(activeIndex)"
            @mouseover="mouseover(item)"
            @click="updateActiveIndex(item, '', '', '', i)"
            class="box"
          >
            {{ item[keyname] }}
          </span>
          <span
            slot="reference"
            class="active clild-name1 box"
            v-if="activeIndex === item[emitKeyName || 'id'] || (item.idList && item.idList.includes(activeIndex))"
            @mouseover="mouseover(item)"
          >
            {{ selectCategoryName }}
          </span>
        </el-popover>
        <i
          v-if="activeIndex == item[emitKeyName || 'id'] || (item.idList && item.idList.includes(activeIndex))"
          @click.stop="selectAll"
          :class="'el-icon-error close-icon '"
        ></i>
      </li>
      <li class="more" v-if="needShowMoreTool" @click="isShowMore = !isShowMore">
        <span v-if="!isShowMore">收起<i :class="'el-icon-arrow-up'"></i></span>
        <i v-if="isShowMore" :class="'el-icon-more'"></i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['title', 'list', 'activeIndex', 'keyname', 'areaLevel', 'hoverList', 'emitKeyName', 'hideAll'],
  name: 'checkRadioGroup',
  computed: {
    needShowMoreTool() {
      // 需要动态获取dom 高度去计算
      return true
    }
  },
  data() {
    return {
      isShowMore: true,
      selectCategoryName: '',
      gridData: [],
      infoList: []
    }
  },
  watch: {
    list: {
      handler(value) {
        if (value && value[0] && value[0].idList === undefined) {
          const array = JSON.parse(JSON.stringify(value))
          value.forEach((item, index) => {
            const idList = []
            if (Array.isArray(item.childrenList)) {
              item.childrenList.forEach(itm => {
                idList.push(itm.id)
                itm.childrenList.forEach(child => {
                  idList.push(child.id)
                })
              })
              array[index].idList = idList
            }
          })
          this.infoList = array
          return
        }
        this.infoList = value
      },
      immediate: true
    },
    activeIndex: {
      handler(value) {
        let name = ''
        this.infoList &&
          this.infoList.forEach(item => {
            if (item.id === value) {
              name = `${item[this.keyname]}`
            } else {
              item.childrenList &&
                item.childrenList.forEach(itm => {
                  if (itm.id === value) {
                    name = `${item[this.keyname]}/${itm.name}`
                  } else {
                    itm.childrenList &&
                      itm.childrenList.forEach(child => {
                        if (child.id === value) {
                          name = `${item[this.keyname]}/${itm.name}/${child.name}`
                        }
                      })
                  }
                })
            }
          })
        this.selectCategoryName = name
      },
      immediate: true
    }
  },
  methods: {
    selectChidLsit(row) {
      this.$emit('selectHoverLsit', row.id)
    },
    selectAll() {
      this.$emit('update:activeIndex', '')
      this.$emit('cancalHoverList')
    },
    mouseover(row) {
      if (this.activeIndex) {
        return
      }
      this.$emit('changeHoverList', row.id)
    },
    updateActiveIndex(item, itm, child, type, index) {
      if (index !== undefined) {
        this.list[index].showPopover = false
      }
      if (type === 'child') {
        this.selectCategoryName = `${item[this.keyname]}/${itm.name}/${child.name}`
      }
      if (type !== 'child' && itm) {
        this.selectCategoryName = `${item[this.keyname]}/${itm.name}`
      }
      if (type !== 'child' && !itm) {
        this.selectCategoryName = `${item[this.keyname]}`
      }
      const info = child ? child : itm ? itm : item
      this.$emit('update:activeIndex', info[this.emitKeyName || 'id'])
    }
  }
}
</script>

<style lang="scss" scoped>
.check-box {
  display: flex;
  padding: 5px 0;
  p {
    width: 160px;
    // margin-right: 20px;
    padding-left: 40px;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
  }
  ul {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    height: auto;
  }
  li {
    position: relative;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-right: 20px;
  }
  .active {
    background: #55cbc4;
    color: #fff;
    border-radius: 4px;
  }
}
.box {
  height: 36px;
  line-height: 36px;
  padding: 5px 22px;
}
.item-box {
  position: relative;
  padding-right: 40px;
  li.more {
    position: absolute;
    right: -20px;
    top: 0;
    width: 100px;
    text-align: center;
    cursor: pointer !important;
  }
}
.isShowMore {
  height: 45px !important;
  overflow: hidden;
}

.close-icon {
  position: absolute;
  right: -25px;
  top: 8px;
  // margin-left: 15px;
  color: #666666;
  z-index: 1000;
}

.pop-box {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}
.children-content {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 40px 36px;
}
.sub-title {
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
  margin-bottom: 20px;
  cursor: pointer;
}
.sub-title span {
  max-width: 124px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
.point {
  width: 8px;
  height: 8px;
  background: #5ad3cd;
  margin: 7px 8px 0 0;
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;
}
.children-option {
  width: 141px;
  margin-right: 10px;
}
.children-name {
  margin-bottom: 20px;
  display: inline-block;
  overflow: hidden;
  width: 141px;
}
.option-name {
  display: inline-block;
  max-width: 99px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  vertical-align: top;
}
.hot {
  width: 36px;
  height: 18px;
  background: rgba(255, 114, 76, 0.1);
  border-radius: 10px;
  border: 1px solid #ff724c;
  font-size: 13px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff724c;
  line-height: 16px;
  text-align: center;
  display: inline-block;
  margin-left: 6px;
  vertical-align: top;
}
.clild-name {
  padding: 0 !important;
}
.clild-name1 {
  padding: 5px 22px;
}
/deep/.el-popover {
  padding: 0 !important;
}
</style>
<style>
.popover {
  box-shadow: 6px 12px 10px 0px rgba(203, 203, 203, 0.36) !important;
  border-radius: 10px !important;
  padding: 0 !important;
}
.pleasehloder {
  height: 36px;
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #e3e3e3 33%, #ffffff 68%, #ffffff 100%);
  opacity: 0.2;
  margin-top: -6px;
}
</style>
