import Vue from 'vue'
import store from '../store/index'
import {formatTimeFilter} from '../utils'
// console.log('store', store)

Vue.filter('formatTimeFilter', formatTimeFilter)

Vue.filter('businessIdFilter', function(id, filterArray) {
  // const ss = localStorage.getItem('BusinessList')
  const BusinessList = filterArray && filterArray.length ? filterArray : store.state.BusinessList || []
  // const BusinessList = ss ? JSON.parse(ss) : []
  return BusinessList.softwareDevelopmentList.filter(item => item.id == id)[0]?.name
})

Vue.filter('businessTypeFilter', function(id, filterArray) {
  // const ss = localStorage.getItem('BusinessList')
  // const BusinessList = ss ? JSON.parse(ss) : []
  const BusinessList = filterArray && filterArray.length ? filterArray : store.state.BusinessList || []
  return BusinessList.softwareDevelopmentList.filter(item => item.id == id)[0]?.name
})

Vue.filter('AreaListFilter', function(val, filterArray) {
  // const ss = localStorage.getItem('AreaList')
  // const AreaList = ss ? JSON.parse(ss) : []
  // console.log('tag。。。。。。。', AreaList.filter(item => item.id == val)[0]?.name)
  const AreaList = filterArray && filterArray.length ? filterArray : store.state.AreaList || []
  if (!AreaList.length) return
  return AreaList.filter(item => item.id == val && item.level == 2)[0]?.name
})

Vue.filter('CompanyListFilter', function(val, filterArray) {
  // const ss = localStorage.getItem('CompanyList')
  // const CompanyList = ss ? JSON.parse(ss) : []
  const CompanyList = filterArray && filterArray.length ? filterArray : store.state.CompanyList || []
  if (!CompanyList.length) return
  // console.log('tag。。。。。。。', CompanyList.filter(item => item.id == val)[0]?.shortName)
  return CompanyList.filter(item => item.id == val)[0]?.shortName
})

Vue.filter('EmployeeDomainListFilter', function(val, filterArray) {
  // const ss = localStorage.getItem('EmployeeDomainList')
  // const EmployeeDomainList = ss ? JSON.parse(ss) : []
  const EmployeeDomainList = filterArray && filterArray.length ? filterArray : store.state.EmployeeDomainList || []
  if (!EmployeeDomainList.length) return
  return EmployeeDomainList.filter(item => item.id == val)[0]?.name
})

Vue.filter('EmployeeOccupationListFilter', function(val, filterArray) {
  // const ss = localStorage.getItem('EmployeeOccupationList')
  // const EmployeeOccupationList = ss ? JSON.parse(ss) : []
  const EmployeeOccupationList =
    filterArray && filterArray.length ? filterArray : store.state.EmployeeOccupationList || []
  if (!EmployeeOccupationList.length) return
  // console.log('tag。。。。。。。', EmployeeOccupationList.filter(item => item.id == val)[0]?.name)
  return EmployeeOccupationList.filter(item => item.id == val)[0]?.name
})

Vue.filter('EmployeeStationListFilter', function(val, filterArray) {
  // const ss = localStorage.getItem('EmployeeStationList')
  // const EmployeeStationList = ss ? JSON.parse(ss) : []
  const EmployeeStationList = filterArray && filterArray.length ? filterArray : store.state.EmployeeStationList || []
  if (!EmployeeStationList.length) return
  // console.log('tag。。。。。。。', EmployeeStationList.filter(item => item.id == val)[0]?.name)
  return EmployeeStationList.filter(item => item.id == val)[0]?.name
})

Vue.filter('mobileFilters', function(val) {
  if (!val) return
  return val.slice(0, 3) + '****' + val.slice(8)
})

Vue.filter('EducationListFilter', function(val, filterArray) {
  // const ss = localStorage.getItem('EducationList')
  // const EducationList = ss ? JSON.parse(ss) : []
  const EducationList = filterArray && filterArray.length ? filterArray : store.state.EducationList || []

  if (!EducationList.length) return
  // console.log('tag。。。。。。。', EducationList.filter(item => item.id == val)[0]?.shortName)
  return EducationList.filter(item => item.id == val)[0]?.name
})

Vue.filter('yearoldFilters', function(val) {
  if (!val) return

  function ages(str) {
    var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/)
    if (r == null) return false
    var d = new Date(r[1], r[3] - 1, r[4])
    if (d.getFullYear() == r[1] && d.getMonth() + 1 == r[3] && d.getDate() == r[4]) {
      var Y = new Date().getFullYear()
      return '' + (Y - r[1]) + '  岁'
    }
    return ''
    // 输入的日期格式错误！
  }

  return ages(val.slice(0, 10))
})
