<template>
  <div class="footer-container">
    <div class="footerbox">
      <div class="top">
        <div class="topTitle">
          <div class="blockitembox" v-for="(item, index) in baseData" :key="index">
            <p>{{ item.name }}</p>
            <ul>
              <li v-for="(itemdata, i) in item.children" :key="i" class="hoverstyle">
                <span @click="$router.push(itemdata.link)">{{ itemdata.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div><span class="title">全倾</span></div>
          <ul>
            <li>
              <a href="https://beian.miit.gov.cn/">京ICP备2020037621号-1</a>
            </li>
            <li>
              <a href="https://beian.miit.gov.cn/">京ICP备2020037621号-2</a>
            </li>
            <li>京B2-20212258</li>
            <li>北京市海淀区五道口东升大厦A座9层</li>
            <li>contact@quanqing.com</li>
          </ul>
        </div>
      </div>
      <div class="bottom">
        <div>友情链接：</div>
        <ul>
          <li v-for="(item, i) in goodPartner" :key="i" @click="openNewPage(item.link)" class="hoverstyle">
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commonFooter',
  data() {
    return {
      baseData: Object.freeze([
        {
          name: '主营服务',
          link: '',
          children: [
            {name: '网站建设', link: '/result?SearchInput=网站建设'},
            {name: 'APP开发', link: '/result?SearchInput=APP开发'},
            {name: '小程序开发', link: '/result?SearchInput=小程序开发'},
            {name: '自有系统开发', link: '/result?SearchInput=自有系统开发'}
          ]
        },
        {
          name: '全倾技术标准',
          link: '',
          children: [
            {name: '可用性标准', link: '/quanqingStandard?classId=4'},
            {name: '性能标准', link: '/quanqingStandard?classId=5'},
            {name: '安全标准', link: '/quanqingStandard?classId=6'},
            {name: '架构标准', link: '/quanqingStandard?classId=7'}
          ]
        },
        {
          name: '全倾技术产品',
          link: '',
          children: [
            {name: '页面崩溃监控', link: '/quanqingProduct?classId=5'},
            {name: '性能检测', link: '/quanqingProduct?classId=6'},
            {name: '压力测试', link: '/quanqingProduct?classId=7'},
            {name: '安全测试', link: '/quanqingProduct?classId=8'}
          ]
        },
        {
          name: '入驻',
          link: '',
          children: [
            {name: '技术公司入驻', link: '/enter?selectType=1'},
            {name: '兼职员工入驻', link: '/enter?selectType=3'},
            {name: '兼职团队入驻', link: '/enter?selectType=2'}
            // {name: '全职员工入驻', link: ''}
          ]
        },
        {
          name: '合作',
          link: '',
          children: [
            {name: '公司推广分佣', link: '/companyCommission'},
            {name: '个人推广分佣', link: '/employCommission'}
          ]
        },
        {
          name: '关于',
          link: '',
          children: [
            {name: '全倾介绍', link: '/quanqingAbout'},
            {name: '联系全倾', link: '/connectionQuanqing'}
          ]
        }
      ]),
      goodPartner: Object.freeze([
        {
          name: '腾讯云',
          link: 'https://cloud.tencent.com'
        },
        {
          name: '阿里云',
          link: 'https://www.aliyun.com'
        },
        {
          name: '公司宝',
          link: 'https://www.gongsibao.com'
        }
      ])
    }
  },
  methods: {
    openNewPage(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-container {
  margin-left: 15px;
  width: 100%;
  height: 328px;
  background: #ffffff;
}
.footerbox {
  width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 45px;

  .top {
    width: 100%;
    display: flex;

    .topTitle {
      display: flex;
      font-size: 16px;
      flex: 1;
      .blockitembox {
        flex: 1;
        margin-right: 30px;
      }
      p {
        font-weight: bold;
        margin-bottom: 15px;
        color: #333333;
        margin-bottom: 15px;
      }
      ul > li {
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .right {
    text-align: right;
    width: 350px;
    .title {
      margin-left: auto;
      display: block;
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      background: #5ad3cd;
      border-radius: 2px;
      color: #ffffff;
    }
    ul {
      margin-top: 20px;
    }
    ul > li {
      height: 25px;
      line-height: 25px;
      color: #999999;
      line-height: 22px;
    }
    ul > li > a {
      height: 25px;
      line-height: 25px;
      color: #999999;
      line-height: 22px;
    }
  }
  .bottom {
    display: flex;
    color: #999999;
    margin-top: 60px;
    div {
      flex: 1;
    }
    ul {
      width: 1100px;
      display: flex;
      flex-wrap: wrap;
      li {
        margin-right: 50px;
        cursor: pointer;
      }
    }
  }
}
</style>
