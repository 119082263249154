import dayjs from 'dayjs'
import Vue from 'vue'
const Vm = new Vue()

export const formatTimeFilter = function(data, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!data) return ''
  return dayjs(data).format(format)
  // moment().format('YYYY-MM-DD HH:mm:ss') //2014-09-24 23:36:09
  // moment().format('LTS') // 18:38:39
  // moment().format('l') // 2021/4/4
}
const trim0AfterDot = num => {
  const splitArr = num.toString().split('.')
  if (splitArr[1] == '00') {
    return splitArr[0]
  }
  if (splitArr[1][0] != '0' && splitArr[1][1] == '0') {
    return splitArr[0] + '.' + splitArr[1][0]
  }
  return num
}
export const setPriceNum = (val, newName) => {
  let price = ''
  if (!val && newName) {
    return newName
  }
  if (!val) {
    return ''
  }
  if (val && val < 10000) {
    price = `${trim0AfterDot(val.toFixed(2))}`
  }
  if (val && val >= 10000) {
    price = `${trim0AfterDot((val / 10000).toFixed(2))}万`
  }
  return price
}

export const verifyPhone = phone => {
  const regs = /^((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/
  if (!phone) {
    Vm.$message.error('请输入手机号')
    return false
  }
  if (!regs.test(phone)) {
    Vm.$message.error('请输入正确的手机号')
    return false
  }
  return true
}

export const toBaiduPage = function(address) {
  var yourWindow = window.open()
  yourWindow.opener = null
  yourWindow.location = `${encodeURI('https://ditu.so.com/?k=' + address + '  &src=tab_www')}`
  yourWindow.target = '_blank'
}
