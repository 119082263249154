import './css/resetCommoncss.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyLoad from 'vue-lazyload'
import Vuebar from 'vuebar'
import Alert from './components/alert/index.js'
import Dialog from './components/dialog/index.js'
import './filter' //注册全局公用的过滤器
import mixins from './mixin'
import {log} from './utils/log'
window.log = log
// 解决路由重复点击时报错
import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

// import * as Sentry from '@sentry/vue'
// import {Integrations} from '@sentry/tracing'

// Sentry.init({
//   Vue,
//   dsn: 'https://7d0c8a005aaa4d94925ceeba1bdcbff2@o306454.ingest.sentry.io/5758039',
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0
// })

import layout from '@/components/layout'
import checkRadioGroup from '@/components/checkRadioGroup'
import checkRadioGroupNew from '@/components/checkRadioGroupNew'
import checkRadioOnlyone from '@/components/checkRadioOnlyone'
import checkRadioGroupLarge from '@/components/checkRadioGroupLarge'
import buttonRadioGroup from '@/components/buttonRadioGroup'
import buttonCheckGroup from '@/components/buttonCheckGroup'
import newButtonCheckGroup from '@/components/newButtonCheckGroup'
import appItemWrapper from '@/components/app-item-container'
import pcItemWrapper from '@/components/PC-item-container'
import ElementUI from 'element-ui'
import '../theme/index.css'
import uploadTemplate from './components/uploadTemplate'
import detailPageCommonHeader from './components/detailPageCommonHeader'
import avaderImg from './components/avader'
import teamListItem from './components/teamListItem'
import persionListItem from './components/persionListItem'
import companyListItem from './components/companyListItem'
import draggable from 'vuedraggable'

import companytag from './components/companytag'
import domaintag from './components/domaintag'
import addresstag from './components/addresstag'

// import 'element-ui/lib/theme-chalk/index.css';

// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill
// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

// Vue.component('commonHeader', CommonHeader)
// Vue.component('commonFooter', CommonFooter)
Vue.component('layoutContainer', layout)
Vue.component('checkRadioGroup', checkRadioGroup)
Vue.component('checkRadioGroupNew', checkRadioGroupNew)
Vue.component('checkRadioOnlyone', checkRadioOnlyone)
Vue.component('checkRadioGroupLarge', checkRadioGroupLarge)
Vue.component('buttonRadioGroup', buttonRadioGroup)
Vue.component('buttonCheckGroup', buttonCheckGroup)
Vue.component('newButtonCheckGroup', newButtonCheckGroup)
Vue.component('uploadTemplate', uploadTemplate)
Vue.component('appItemWrapper', appItemWrapper)
Vue.component('pcItemWrapper', pcItemWrapper)
Vue.component('detailPageCommonHeader', detailPageCommonHeader)
Vue.component('avaderImg', avaderImg)
Vue.component('teamListItem', teamListItem)
Vue.component('persionListItem', persionListItem)
Vue.component('companyListItem', companyListItem)
Vue.component('draggable', draggable)
Vue.component('companytag', companytag)
Vue.component('domaintag', domaintag)
Vue.component('addresstag', addresstag)

Vue.config.productionTip = false
// Vue.prototype.$alert = alert

Vue.use(ElementUI)
Vue.use(Dialog)
Vue.use(Alert)
Vue.use(Vuebar)
Vue.use(VueLazyLoad, {
  error: './static/error.png',
  loading: './static/loading.png'
})

Vue.mixin(mixins)
/* 
<img class="item-pic" v-lazy="newItem.picUrl"/>
<div v-lazy:background-image="imgLink"></div> 
*/
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
