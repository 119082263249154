import Vue from 'vue'
import VueRouter from 'vue-router'
import pageMixins from '../mixin'

const extendMixin = (instance, ...mixins) => {
  instance.mixins = instance.mixins ? instance.mixins.concat(mixins) : mixins
  return instance
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: null,
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/login.vue')
  },
  /**
   * 首页相关
   */
  {
    path: '/home',
    name: 'Home',
    // component: () => import(/* webpackChunkName: "Home" */ '../views/home.vue')
    component: () => import(/* webpackChunkName: "Home" */ '../views/newHome.vue')
  },
  {
    path: '/project',
    name: 'Project',
    component: () => import(/* webpackChunkName: "Project" */ '../views/project/index.vue')
  },
  {
    path: '/projectDetail',
    name: 'Project',
    component: () => import(/* webpackChunkName: "Project" */ '../views/project/projectDetail.vue')
  },

  {
    path: '/quanqingProtocol',
    name: 'quanqingProtocol',
    component: () => import(/* webpackChunkName: "quanqing" */ '../views/quanqing/quanqingAbout.vue')
  },
  {
    path: '/quanqingAbout',
    name: 'quanqingAbout',
    component: () => import(/* webpackChunkName: "quanqing" */ '../views/quanqing/quanqingAbout.vue')
  },
  {
    path: '/connectionQuanqing',
    name: 'connectionQuanqing',
    component: () => import(/* webpackChunkName: "quanqing" */ '../views/quanqing/quanqingAbout.vue')
  },
  {
    path: '/companyCommission',
    name: 'companyCommission',
    component: () => import(/* webpackChunkName: "quanqing" */ '../views/quanqing/quanqingAbout.vue')
  },
  {
    path: '/employCommission',
    name: 'employCommission',
    component: () => import(/* webpackChunkName: "quanqing" */ '../views/quanqing/quanqingAbout.vue')
  },
  {
    path: '/techVideo',
    name: 'techVideo',
    component: () => import(/* webpackChunkName: "techVideo" */ '../views/techVideo/index.vue')
  },
  {
    path: '/techVideoDetail',
    name: 'techVideoDetail',
    component: () => import(/* webpackChunkName: "techVideo" */ '../views/techVideo/techVideoDetail.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import(/* webpackChunkName: "pay" */ '../views/techVideo/pay.vue')
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: () => import(/* webpackChunkName: "my" */ '../views/techVideo/orderList.vue')
  },
  /**
   * 搜索结果页
   */
  {
    path: '/result',
    name: 'result',
    component: () => import(/* webpackChunkName: "result" */ '../views/result.vue')
  },
  /**
   * 我的相关
   */ {
    path: '/my',
    name: 'my',
    component: () =>
      import(/* webpackChunkName: "my" */ '../views/my.vue').then(page => extendMixin(page.default, pageMixins))
  },
  /**
   * 公司相关
   */
  {
    path: '/company',
    name: 'Company',
    component: () => import(/* webpackChunkName: "Company" */ '../views/company/company.vue')
  },
  {
    path: '/companyDetail/:id',
    name: 'CompanyDetail',
    component: () =>
      import(/* webpackChunkName: "Company" */ '../views/company/companyDetail.vue').then(page =>
        extendMixin(page.default, pageMixins)
      )
  },
  {
    path: '/company-case-detail/:id',
    name: 'companyCaseDetail',
    component: () =>
      import(/* webpackChunkName: "Company" */ '../views/company/company-case-detail.vue').then(page =>
        extendMixin(page.default, pageMixins)
      )
  },
  {
    path: '/company-case-add',
    name: 'companyCaseAdd',
    component: () =>
      import(/* webpackChunkName: "Company" */ '../views/company/company-case-add.vue').then(page =>
        extendMixin(page.default, pageMixins)
      )
  },
  /**
   * 团队相关
   */
  {
    path: '/teams',
    name: 'teams',
    component: () => import(/* webpackChunkName: "teams" */ '../views/teams/teams.vue')
  },
  {
    path: '/teams/detail/:id',
    name: 'teams-detail',
    component: () =>
      import(/* webpackChunkName: "teams" */ '../views/teams/detail.vue').then(page =>
        extendMixin(page.default, pageMixins)
      )
  },
  /**
   * 个人相关
   */
  {
    path: '/personal',
    name: 'personal',
    component: () =>
      import(/* webpackChunkName: "personal" */ '../views/personal/index.vue').then(page =>
        extendMixin(page.default, pageMixins)
      )
  },
  {
    path: '/personal/detail/:id',
    name: 'personalDetail',
    component: () =>
      import(/* webpackChunkName: "personal" */ '../views/personal/personal-detail.vue').then(page =>
        extendMixin(page.default, pageMixins)
      )
  },
  {
    path: '/personal-case-detail/:id',
    name: 'personalCaseDetail',
    component: () =>
      import(/* webpackChunkName: "personal" */ '../views/personal/personal-case-detail.vue').then(page =>
        extendMixin(page.default, pageMixins)
      )
  },
  {
    path: '/personal-case-add',
    name: 'personalCaseAdd',
    component: () =>
      import(/* webpackChunkName: "personal" */ '../views/personal/personal-case-add.vue').then(page =>
        extendMixin(page.default, pageMixins)
      )
  },
  /**
   * 服务相关
   */
  {
    path: '/serve',
    name: 'serve',
    component: () => import(/* webpackChunkName: "serve" */ '../views/serve/index.vue')
  },
  {
    path: '/serve/detail',
    name: 'serveDetail',
    component: () => import(/* webpackChunkName: "serve" */ '../views/serve/serveDetail.vue')
  },
  /**
   * 产品相关
   */
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '../views/product/index.vue')
  },
  {
    path: '/product/detail',
    name: 'productDetail',
    component: () => import(/* webpackChunkName: "product" */ '../views/product/productDetail.vue')
  },
  /**
   * 全倾相关  技术标准、技术产品
   */
  {
    path: '/quanqingStandard',
    name: 'quanqingStandard',
    // component: () => import(/* webpackChunkName: "quanqing" */ '../views/quanqing/quanqingStandard.vue')
    component: () => import(/* webpackChunkName: "quanqing" */ '../views/quanqing/newStandard.vue')
  },
  {
    path: '/quanqingProduct',
    name: 'quanqingProduct',
    component: () => import(/* webpackChunkName: "quanqing" */ '../views/quanqing/quanqingProduct.vue')
  },
  /**
   * 入驻相关
   */
  {
    path: '/enter',
    name: 'enter',
    component: () =>
      import(/* webpackChunkName: "enter" */ '../views/enter/index.vue').then(page =>
        extendMixin(page.default, pageMixins)
      )
  },
  {
    path: '/teamEnter',
    name: 'teamEnter',
    component: () =>
      import(/* webpackChunkName: "enter" */ '../views/enter/teamEnter.vue').then(page =>
        extendMixin(page.default, pageMixins)
      )
  },
  {
    path: '/personalEnter',
    name: 'personalEnter',
    component: () =>
      import(/* webpackChunkName: "enter" */ '../views/enter/personalEnter.vue').then(page =>
        extendMixin(page.default, pageMixins)
      )
  },
  {
    path: '/companyEnter',
    name: 'companyEnter',
    component: () =>
      import(/* webpackChunkName: "enter" */ '../views/enter/companyEnter.vue').then(page =>
        extendMixin(page.default, pageMixins)
      )
  },
  /**
   * 后台页面
   */
  {
    path: '/greenRoom',
    name: 'greenRoom',
    component: () =>
      import(/* webpackChunkName: "greenRoom" */ '../views/greenRoom/index.vue').then(page =>
        extendMixin(page.default, pageMixins)
      )
  },
  /**
   * 发布需求外链专用通道
   */
  {
    path: '/marketing',
    name: 'marketing',
    component: () => import(/* webpackChunkName: "marketing" */ '../components/marketing.vue')
  },
  /**
   * 发布需求外链专用通道
   */
  {
    path: '/imgView',
    name: 'imgView',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/imgView/index.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})
// 全局路由守卫
// router.beforeEach((to, from, next) => {
//   console.log('navigation-guards');
//   // to: Route: 即将要进入的目标 路由对象
//   // from: Route: 当前导航正要离开的路由
//   // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。

//   const nextRoute = ['home', 'good-list', 'good-detail', 'cart', 'profile'];// 是否是白名单的页面
//   let isLogin = global.isLogin;  // 是否登录
//   // 未登录状态；当路由到nextRoute指定页时，跳转至login
//   if (nextRoute.indexOf(to.name) >= 0) {
//     if (!isLogin) {
//       console.log('what fuck');
//       router.push({ name: 'Login' })
//     }
//   }
//   // 已登录状态；当路由到login时，跳转至home
//   if (to.name === 'login') {
//     if (isLogin) {
//       router.push({ name: 'home' });
//     }
//   }
//   next();
// });
export default router
