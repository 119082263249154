<template>
  <ul class="item-box">
    <li v-for="(item, i) in businessList" :key="i" @click="emitToFather(item)">
      <span :class="value == item.id ? 'active' : ''"> {{ item.name }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['businessList', 'value'],
  name: 'buttonRadioGroup',
  computed: {},
  methods: {
    emitToFather(item) {
      this.$emit('update:value', item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  height: auto;
}
li {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  span {
    height: 36px;
    line-height: 36px;
    padding: 5px 22px;
    border-radius: 18px;
    border: 1px solid #dedede;
    background: #ffffff;
    color: #999999;
    margin-right: 10px;
  }
}
.active {
  border: 1px solid #55cbc4;
  background: #55cbc4;
  color: #fff;
}
</style>
