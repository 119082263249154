import Vue from 'vue'
import Vuex from 'vuex'
// import persistedstate from 'vuex-persistedstate'
import {getUserInfoApi} from '../api/index'

Vue.use(Vuex)
const UserInfo = localStorage.getItem('UserInfo')
export default new Vuex.Store({
  // plugins: [persistedstate()],
  state: {
    SearchInput: '',
    UserInfo: UserInfo ? JSON.parse(UserInfo) : {},
    AreaList: [], // 地区列表
    BusinessList: [], // 业务类型  app 小程序 网站
    EducationList: [], // 学历
    EmployeeDomainList: [], // 擅长领域
    CompanyList: [], //  公司列表
    EmployeeOccupationList: [], // 职业列表
    EmployeeStationList: [], // 岗位列表
    EmployeeDomainClassList: [], // 擅长领域分类
    EmployeeOccupationDomainClassRelationList: [], //职业和擅长领域的关联关系接口
    FamousInternetCompanyShortNameList: [], // 公司简称
    sexList: [
      {
        name: '男',
        id: 1
      },
      {
        name: '女',
        id: 0
      }
    ]
  },
  mutations: {
    ChangeSearchInput(state, n) {
      state.SearchInput = n
    },
    ChangeUserInfo(state, n) {
      state.UserInfo = {...n}
      localStorage.setItem('UserInfo', JSON.stringify(n))
    },
    ChangeAreaList(state, n) {
      localStorage.setItem('AreaList', JSON.stringify(n))
      state.AreaList = [...n]
    },
    ChangeBusinessList(state, n) {
      localStorage.setItem('BusinessList', JSON.stringify(n))
      state.BusinessList = n
    },
    ChangeEducationList(state, n) {
      localStorage.setItem('EducationList', JSON.stringify(n))
      state.EducationList = [...n]
    },
    ChangeEmployeeDomainList(state, n) {
      localStorage.setItem('EmployeeDomainList', JSON.stringify(n))
      state.EmployeeDomainList = [...n]
    },
    ChangeCompanyList(state, n) {
      localStorage.setItem('CompanyList', JSON.stringify(n))
      state.CompanyList = [...n]
    },
    ChangeEmployeeOccupationList(state, n) {
      localStorage.setItem('EmployeeOccupationList', JSON.stringify(n))
      state.EmployeeOccupationList = [...n]
    },
    ChangeEmployeeStationList(state, n) {
      localStorage.setItem('EmployeeStationList', JSON.stringify(n))
      state.EmployeeStationList = [...n]
    },
    ChangeEmployeeDomainClassList(state, n) {
      localStorage.setItem('EmployeeDomainClassList', JSON.stringify(n))
      state.EmployeeDomainClassList = [...n]
    },
    ChangeEmployeeOccupationDomainClassRelationList(state, n) {
      localStorage.setItem('EmployeeOccupationDomainClassRelationList', JSON.stringify(n))
      state.EmployeeOccupationDomainClassRelationList = [...n]
    },
    ChangeFamousInternetCompanyShortNameList(state, n) {
      localStorage.setItem('FamousInternetCompanyShortNameList', JSON.stringify(n))
      state.FamousInternetCompanyShortNameList = [...n]
    }
  },
  actions: {
    async ChangeUserInfoActions({commit}) {
      const UserInfoString = localStorage.getItem('UserInfo')
      const UserInfo = UserInfoString ? JSON.parse(UserInfoString) : {}
      if (UserInfo.phonenumber) {
        const res = await getUserInfoApi({phone: UserInfo.phonenumber})
        commit('ChangeUserInfo', res?.data || {})
      }
    }
  },
  modules: {}
})
