<template>
  <div class="img-box" @click="gotoDetailPage">
    <img :src="item[imgKey]" alt="" />
    <p>
      <span class="left"> {{ item.name }}</span>
      <span class="right"> <i class="el-icon-picture-outline"></i> {{ item.picNum }}</span>
    </p>
    <i v-if="isOwnCompany" class="el-icon-error red" @click.stop="deleteCase(item.id)"></i>
  </div>
</template>

<script>
export default {
  name: 'appItemWrapper',
  props: ['item', 'imgKey', 'detailPageUrl', 'isOwnCompany'],
  data() {
    return {
      // isOwnCompany: true // TODO: 联调时候改为 store 获取
    }
  },
  methods: {
    gotoDetailPage() {
      this.$router.push({
        path: this.detailPageUrl,
        query: {detail: JSON.stringify(this.item)}
      })
    },
    deleteCase(id) {
      this.$emit('deleteCase', id)
    }
  }
}
</script>

<style scoped lang="scss">
.img-box {
  width: 240px;
  height: 560px;
  position: relative;
  img {
    width: 240px;
    height: 500px;
    border-radius: 5px;
  }
  p {
    padding-top: 10px;
  }
  .left {
    float: left;
    width: 125px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .right {
    float: right;
  }
}
.red {
  color: #ff773a;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -10px;
  font-size: 20px;
}
</style>
