<template>
  <transition :name="transition">
    <div class="m-dialog" v-if="show">
      <div class="wrapper" v-if="type === 'alert'">
        <header class="header" v-if="title">{{ title }}</header>
        <!-- <div class="content" :data-left="left">{{ msg }}</div> -->
        <div class="content" :data-left="left" v-html="msg"></div>
        <footer class="footer">
          <a @click="onConfirm" class="btn btn-confirm" v-html="configs.btnConfirm"></a>
        </footer>
      </div>
      <div class="wrapper" v-if="type === 'modal'">
        <div class="content" :data-left="left">
          <p class="p" v-html="msg"></p>
          <input type="text" v-if="input" class="input" v-model="value" />
          <p class="error" v-if="isError">{{ msg }}</p>
        </div>
        <footer class="footer">
          <a ref="dom" @click="onCancel" class="btn btn-cancel" v-if="configs.isShowCancelBtn">{{
            configs.btnCancel
          }}</a>
          <a @click="onConfirm" class="btn btn-confirm" v-html="configs.btnConfirm"></a>
        </footer>
      </div>
      <div class="toast" v-if="type === 'toast'">{{ msg }}</div>
      <!-- <div class="mask" :data-type="type" @click.prevent.self="close"></div> -->
      <div class="mask" :data-type="type"></div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      msg: '',
      title: '',
      left: false,
      transition: 'fade',
      value: null,
      isError: false,
      configs: {
        btnConfirm: '确认',
        btnCancel: '取消',
        cancelHandler() {},
        confirmHandler() {},
        isShowCancelBtn: true
      },
      // type => 'alert', 'modal', 'toast'
      type: 'toast',
      input: false,
      show: false
    }
  },
  watch: {
    msg(val) {
      if (val.length > 23) {
        // this.left = true
      }
    },
    show(val) {
      // if (val) {
      //   document.body.style.overflow = 'hidden';
      //   document.documentElement.style.overflow = 'hidden';
      // } else {
      //   document.body.style.overflow = '';
      //   document.documentElement.style.overflow = '';
      // }
    }
  },
  methods: {
    initConfigs(params) {
      this.showHandler(true)
      if (typeof params !== 'object') return
      const {btnConfirm, btnCancel, cancelHandler, confirmHandler} = this.configs
      if (params.btns) {
        this.$set(this.configs, 'btnConfirm', params.btns[0] || btnConfirm)
        this.$set(this.configs, 'btnCancel', params.btns[1] || btnCancel)
      }
      if (params.isShowCancelBtn == false) {
        this.$set(this.configs, 'isShowCancelBtn', false)
      }
      if (params.callback) {
        this.$set(this.configs, 'confirmHandler', params.callback[0] || confirmHandler)
        this.$set(this.configs, 'cancelHandler', params.callback[1] || cancelHandler)
      }
      if (params.input) this.input = params.input || false
    },
    resetConfigs() {
      this.msg = ''
      this.title = ''
      this.value = null
      this.configs = {
        btnConfirm: '确认',
        btnCancel: '取消',
        cancelHandler() {},
        confirmHandler() {},
        isShowCancelBtn: true
      }
    },
    showHandler(toggle) {
      if (!toggle) this.resetConfigs()
      this.show = toggle
    },
    /**
     * 确认弹框
     * @param {string} msg - 提示文字
     * @param {object} params - 配置信息
     * @param {string} params.title - 标题
     * @param {array} params.btns - 确认按钮的文字
     * @param {array} params.callback - 确认执行的回调
     */
    alert(msg, params) {
      this.title = params.title
      this.msg = msg
      this.type = 'alert'
      this.transition = 'fade'
      this.initConfigs(params)
    },
    /**
     * modal 提示，有确认和取消两按钮
     * @param {string} title - 提示标题
     * @param {string} msg - 提示文字
     * @param {array} params.callback - 0 => 确认按钮回调 1 => 取消按钮回调
     * @param {array} params.btns - 按钮文字 0 => 确认 1 => 取消
     */
    modal(title, msg, params = {}) {
      this.msg = msg
      this.type = 'modal'
      this.transition = 'fade'
      this.initConfigs(params)
    },
    /**
     * 吐司提示
     * @param {string} msg - 提示文字
     * @param {object} params - 配置信息
     * @param {number} params.delay - 多少秒后关闭, 默认 3s, 如果为 0 , 则不关闭
     * @param {function} params.callback - 关闭时执行的回调
     */
    toast(msg, params = {}) {
      this.msg = msg
      this.type = 'toast'
      this.transition = 'toast'
      let delay = 2000
      if (typeof params.delay === 'number') {
        delay = params.delay * 1000
      }
      this.show = true

      setTimeout(() => {
        if (typeof params.callback === 'function') {
          params.callback()
        }
        this.show = false
      }, delay || 2000)
    },
    onCancel() {
      this.configs.cancelHandler(this.$refs.dom)
      this.showHandler(false)
    },
    onConfirm() {
      // 加入error的情况
      const promise = new Promise(resolve => {
        resolve(!this.configs.confirmHandler(this.value))
      })
      promise.then(res => {
        if (res) return this.showHandler(false)
        this.isError = true
      })
    },
    close() {
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.m-dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -25%;
  transform: translate3d(0, -50%, 1px);
  margin: auto;
  z-index: 2;
  width: 540px;
  overflow: hidden;
  border-radius: 6px;
  background-color: #fff;
  font-size: 1.1em;

  .p {
    text-align: center;
  }
}

.toast {
  position: absolute;
  left: 0;
  right: 0;
  top: 25%;
  margin-top: -25%;
  /* transform: translate3d(0, -50%, 1PX); */
  margin: 0 auto;
  width: 400px;
  padding: 20px 30px;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 900;
}

.content {
  padding: 0 30px;
  margin: 25px 0;
  line-height: 1.5;
  max-height: 500px;
  overflow: auto;
  word-break: break-all;
  text-align: center;

  &[data-left='true'] {
    text-align: left;
  }

  .input {
    border: 1px solid #cccccc;
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 20px;
  }

  .error {
    color: #ee2a46;
    font-size: 0.8em;
    margin-top: 10px;
  }
}

.header {
  padding: 20px 30px;
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
}

.footer {
  display: flex;
  text-align: center;
  justify-content: space-between;
  border-top: 1px solid #f0f0f0;
}

.btn {
  flex: 1;
  line-height: 88px;
}

.btn-confirm {
  color: #ee2a46;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #f0f0f0;
  }
}

.mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 200%;
  background-color: #000;
  opacity: 0.3;
  z-index: 1;

  &[data-type='toast'] {
    opacity: 0.01;
  }
}
</style>
