<template>
  <div class="group-button">
    <el-checkbox-group v-model="checkValue">
      <el-checkbox-button
        v-for="item in businessList"
        :label="item.id"
        :key="item.id"
        v-if="!needCompare || (needCompare && needCompare.id == item[needCompare.itemKey])"
        >{{ item.name }}</el-checkbox-button
      >
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  props: ['businessList', 'valueList', 'needCompare'],
  name: 'buttonCheckGroup',
  computed: {
    checkValue: {
      set(val) {
        this.$emit('update:valueList', val)
      },
      get() {
        return this.valueList || []
      }
    }
  },
  methods: {
    toggleCheck(item) {
      try {
        let valueList
        if (!this.valueList.includes(item.id)) {
          valueList = [...this.valueList, item.id]
        } else {
          valueList = [...(this.valueList.filter(i => item.id != i) || [])]
        }
        this.$emit('update:valueList', valueList)
      } catch (err) {}
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-checkbox-button {
  margin-right: 10px;
  border-radius: 15px;
  border: 0;
  input {
    border: 0;
  }
  span {
    height: 35px;
    border-left: 1px solid #5ad3cd;
    border-radius: 20px !important;
    border: 1px solid #dedede;
    color: #999999;
    padding-left: 28px;
    padding-right: 28px;
  }
}

.group-button {
  .is-checked {
    /deep/ .el-checkbox-button__inner {
      color: #ffffff;
      background-color: #5ad3cd !important;
      border-color: #5ad3cd;
    }
  }
}

/deep/ .group-button .el-checkbox-button__inner {
  height: 35px;
  line-height: 35px !important;
  background: #5ad3cd !important;
  font-size: 16px;
}
</style>
