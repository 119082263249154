<template>
  <div class="container" @click="gotoCompanyDetailPage(item)" :class="size == 'mini' ? 'mini' : ''">
    <div style="width: 100%; height: 171px;overflow: hidden;">
      <el-image style="width: 100%; height: 171px" :src="item.smallLogoPicUrl" :fit="'contain'"></el-image>
    </div>
    <p class="title">
      {{ item.shortName }}
      <addresstag
        class="home_company_address_tag"
        v-if="item.provinceId && size == 'mini'"
        :text="area_list.findOwnerNameById(item.provinceId)"
      ></addresstag>
    </p>
    <p class="text">
      <span v-if="item.ownCaseNum > 0">{{ item.ownCaseNum }}案例 | </span
      ><span v-if="item.productNum > 0">{{ item.productNum }}产品 | </span>
      <span v-if="item.serviceNum > 0">{{ item.serviceNum }}服务 | </span> <span>{{ item.employeeNum }}人 | </span>
      <span>{{ newYear - item.establishDate.slice(0, 4) }}年</span>
    </p>
    <p class="address" v-if="item.provinceId && !size">
      <addresstag :text="area_list.findOwnerNameById(item.provinceId)"></addresstag>
    </p>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {checkedDeepFormat} from '../utils/findQueryName'

export default {
  computed: {
    ...mapState(['AreaList']),
    area_list() {
      return checkedDeepFormat(this.AreaList) || {}
    }
  },
  name: 'companyListItem',
  props: ['item', 'size'],
  data() {
    return {
      newYear: new Date().getFullYear() + 1
    }
  },
  methods: {
    gotoCompanyDetailPage(item) {
      this.$router.push({
        path: `/companyDetail/${item.id}`
      })
    }
  }
}
</script>

<style scoped>
.home_company_address_tag {
  margin-left: auto;
  margin-right: 20px;
}
.container {
  width: 285px;
  height: 370px;
  border-radius: 8px;
  overflow: hidden;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 20px;

  cursor: pointer;
}
.container:hover {
  box-shadow: 0px 7px 8px 0px rgba(229, 225, 225, 0.5);
}
.mini {
  width: 245px;
  height: 100%;
}

p {
  padding-left: 20px;
}

.title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  /* font-weight: 500; */
  font-weight: 600;
  color: #333333;
  height: 25px;
  line-height: 25px;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
}

.text {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  height: 25px;
  line-height: 25px;
  margin-bottom: 20px;
}

.address {
  height: 20px;
  line-height: 20px;
  color: #5ad3cd;
}
</style>
