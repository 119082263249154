import request from '@/utils/request'
/**
 * 项目意向
 */
// 后台需求列表
export const getProjectIntentionListApi = () =>
  request({
    url: '/projectIntention/list',
    method: 'get'
  })

// 发布需求
export const projectIntentionPushApi = params =>
  request({
    url: `/projectIntention/push`,
    method: 'post',
    data: JSON.stringify(params)
  })

// 后台需求标记处理
export const projectIntentionDealApi = params =>
  request({
    url: `/projectIntention/deal/${params.id}/${params.isDealed}`,
    method: 'post'
    // data: JSON.stringify(params)
  })

// 后台入驻列表
export const companyUncheckedList = params =>
  request({
    url: `/company/uncheckedList`,
    method: 'get',
    params
  })
// 后台入驻列表
export const employeeUncheckedList = params =>
  request({
    url: `/employee/uncheckedList`,
    method: 'get',
    params
  })
// 后台入驻列表
export const teamUncheckedList = params =>
  request({
    url: `/team/uncheckedList`,
    method: 'get',
    params
  })

export const getVipList = params =>
  request({
    url: `/order/refund/application/list`,
    method: 'get',
    params
  })

export const getProductBusiness = params =>
  request({
    url: `/order/refund/application/business`,
    method: 'get',
    params
  })
export const getInvoiceList = params =>
  request({
    url: `/invoice/list`,
    method: 'get',
    params
  })
// 开发票申请业务类型 下拉框
export const getInvoiceBusinessApi = params =>
  request({
    url: `/invoice/business`,
    method: 'get',
    params
  })

export const orderPassed = params =>
  request({
    url: `/order/refund/application/${params.applyId}/passed`,
    method: 'post'
  })

export const orderRejected = params =>
  request({
    url: `/order/refund/application/${params.applyId}/rejected`,
    method: 'post'
  })

export const invoicePassrd = params =>
  request({
    url: `/invoice/${params.teamId}/passed`,
    method: 'post'
  })

export const innvoiceRejected = params =>
  request({
    url: `/invoice/${params.applyId}/rejected`,
    method: 'post'
  })

// 申请开票
export const applyInvoice = params =>
  request({
    url: `/invoice/${params.orderCode}/apply`,
    method: 'post'
  })

// 查询轮播内容列表  background
export const getBannerTextApi = () =>
  request({
    url: '/index/slide/list',
    method: 'get'
  })

// 查询轮播图列表  background
export const getBannerPicApi = () =>
  request({
    url: '/index/slide/pic/list',
    method: 'get'
  })

// 保存轮播图
export const saveBannerPicApi = params =>
  request({
    url: '/index/slide/pic/save',
    method: 'post',
    data: params
  })

// 删除轮播图
export const deleteBannerPicApi = params =>
  request({
    url: `/index/slide/pic/${params.id}`,
    method: 'delete'
  })
// 保存轮播内容
export const saveBannerTextApi = params =>
  request({
    url: 'index/slide/save',
    method: 'post',
    data: params
  })

// 删除轮播图内容
export const deleteBannerTextApi = params =>
  request({
    url: `/index/slide/${params.id}`,
    method: 'delete'
  })
